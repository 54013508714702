@font-face {
  font-family: 'Poppins';
  src: url('../public/static/Poppins.ttf');
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.bg {
  position: relative;
  box-sizing: border-box;
  padding: 5vh 5vw;
  background-image: url('../public/static/bgi.jpg');
  background-size: cover;
  width: 100vw;
  height: 100vh;
  min-width: 1320px;
}

.icon {
  background-image: url('../public/static/icon.png');
  width: 289px;
  height: 56px;
  background-size: cover;
}

.title {
  font-size: 50px;
  font-family: 'Poppins';
  background: linear-gradient(180deg, #9747ff, #ffffff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; /* fallback for non-webkit browsers */
  -webkit-text-fill-color: transparent; /* for webkit browsers */
  position: absolute;
  top: 30%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.download {
  position: absolute;
  bottom: 30%;
}

.ios {
  width: 341px;
  height: 56px;
  background-image: url('../public/static/ios.png');
  background-size: cover;
  cursor: pointer;
}
.android {
  margin-top: 20px;
  width: 341px;
  height: 56px;
  background-image: url('../public/static/android.png');
  background-size: cover;
  cursor: pointer;
}

.phone {
  width: 600px;
  height: 940px;
  background-image: url('../public/static/phone.png');
  background-size: cover;
  position: absolute;
  position: absolute;
  bottom: 0;
  right: 5vw;
}

.footer {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

a {
  color: white;
  font-family: 'Poppins';
  font-size: 20px;
}



